import React from 'react';
import { NavLinkProps } from './types';
import { classJoin } from '../../helpers';

export const NavLink = (props: NavLinkProps) => {
	const { as: Component = 'a', eventKey, active, disabled, role = props.href ? 'link' : 'button', ...otherProps } = props;

	const navLinkClasses = classJoin('nav-link', active && 'active', disabled && 'disabled');

	return (
		<Component {...otherProps} tabIndex={disabled ? undefined : 0} className={navLinkClasses} role={role} data-event-key={eventKey}>
			{props.children}
		</Component>
	);
};
