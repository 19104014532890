import React from 'react';
import { IconSortable } from './IconSortable';
import { HeaderContext } from '@tanstack/react-table';

interface SortButtonProps<TData = unknown, TValue = unknown> extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	header: HeaderContext<TData, TValue>;
}

export function SortButton<TData = unknown, TValue = unknown>({ id, header, children, className, ...props }: SortButtonProps<TData, TValue>): JSX.Element {
	const classes = ['btn btn-table-sort pe-4', className].filter(Boolean).join(' ');
	const headerDef = header.column.columnDef.header;
	const headerText = typeof headerDef === 'string' ? headerDef : headerDef?.(header);

	return (
		<button
			id={id}
			title={`Toggle ${headerText || header.column.id} Sort`}
			aria-label={`Toggle ${headerText || header.column.id} Sort`}
			type="button"
			className={classes}
			onClick={header.column.getToggleSortingHandler()}
			{...props}>
			{children}
			<span className="position-relative">
				<span className="position-absolute">
					<IconSortable column={header.column} />
				</span>
			</span>
		</button>
	);
}
