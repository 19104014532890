import React, { cloneElement, isValidElement, useState, MouseEvent } from 'react';
import { NavProps } from './types';
import { NavItem } from './NavItem';
import { NavLink } from './NavLink';
import { classJoin } from '../../helpers';
import { EventKey } from '../../structure';

export const Nav = (props: NavProps) => {
	const { as: Component = 'div', justify, fill, defaultActiveKey, activeKey, className, children, onSelect, ...otherProps } = props;

	const [currentActiveKey, setCurrentActiveKey] = useState(defaultActiveKey ?? activeKey);
	const navClasses = classJoin('nav', fill && 'nav-fill', justify && 'nav-justified', className);

	function onSelectItem(key: EventKey, e: MouseEvent<HTMLElement>) {
		setCurrentActiveKey(key);
		onSelect?.(key.toString());
	}

	const childrenArray = Array.isArray(children) ? children : [children];
	const preparedChildren = childrenArray.map((child, i) => {
		if (!isValidElement(child)) return child;

		const childProps = (child as React.ReactElement).props;
		const childEventKey = childProps.eventKey ?? childProps.href;
		return cloneElement(child, {
			key: i,
			eventKey: childEventKey,
			active: currentActiveKey === childEventKey,
			onClick: (e: MouseEvent<HTMLElement>) => onSelectItem(childEventKey, e)
		});
	});

	return (
		<Component {...otherProps} className={navClasses}>
			{preparedChildren}
		</Component>
	);
};

Nav.Item = NavItem;
Nav.Link = NavLink;
