import React from 'react';
import { ConditionalElement } from '../../../types';
import { AppliedFilters } from './AppliedFilters';
import { useTranslatableText } from '../../../translatable-text';
import { rowSpacer } from '../utility';
import { Column } from '@tanstack/react-table';

interface FilterResultsProps<TData = unknown, TValue = unknown> {
	columns: Column<TData, TValue>[];
	resultsLength: number;
	searchCriteria?: string;
	filterLabelsMapping?: (columnId: string, value: unknown) => string | undefined;
}

export const resultsFoundTestId = 'filtered-results';

export function FilterResults<TData = unknown, TValue = unknown>({
	columns,
	resultsLength,
	searchCriteria = '',
	filterLabelsMapping
}: FilterResultsProps<TData, TValue>): ConditionalElement {
	const { searchResultsText, filterResultsText } = useTranslatableText();
	const resultsFoundText = searchCriteria ? searchResultsText + ` "${searchCriteria}"` : filterResultsText;

	const filteredColumns = columns.filter(column => column.getIsFiltered());

	const areResultsFiltered = filteredColumns.length > 0 || searchCriteria;
	return areResultsFiltered ? (
		<div className={rowSpacer} data-testid={resultsFoundTestId}>
			<div className="h3 text-break">{`${resultsFoundText} - ${resultsLength}`}</div>
			<AppliedFilters columns={filteredColumns} filterLabelsMapping={filterLabelsMapping} />
		</div>
	) : null;
}
