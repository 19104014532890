import { CellContext as TanStackCellContext, HeaderContext, Row as TanStackRow, Column as TanStackColumn } from '@tanstack/react-table';
import { ColumnHeaderProps, Row, Column, TableInstance, CellProps } from '../types';
import { ToggleRowSelectedProps } from '../row-selection';

export function getRowProps<TData = unknown>(row: TanStackRow<TData>): Row<TData> {
	const rowData: Row<TData> = {
		id: row.index,
		cells: [],
		index: row.index,
		values: row.original,
		original: row.original,
		isExpanded: row.getIsExpanded(),
		toggleRowExpanded: row.getToggleExpandedHandler(),
		getToggleRowExpandedProps: (props: object) => {
			return {
				...props,
				title: 'Toggle Row Expanded'
			};
		},
		isSelected: row.getIsSelected(),
		getToggleRowSelectedProps: (cellProps: CellProps<TData>): ToggleRowSelectedProps => ({
			'style.cursor': 'pointer',
			checked: cellProps.row.isSelected,
			title: 'Toggle Row Selected',
			onChange(val) {
				row.getToggleSelectedHandler()(val);
			}
		}),
		toggleRowSelected(val?: boolean) {
			row.getToggleSelectedHandler()(val);
		}
	};

	rowData.cells = row.getAllCells().map(cell => {
		return {
			row: rowData,
			column: getColumnProps(cell.column),
			value: cell.getValue()
		};
	});

	return rowData;
}

export function getCellProps<TData = unknown, TValue = unknown>(ctx: TanStackCellContext<TData, TValue>): CellProps<TData, TValue> {
	return {
		rows: ctx.table.getRowModel().rows.map(getRowProps<TData>),
		row: getRowProps(ctx.row),
		value: ctx.getValue()
	};
}

export function getColumnProps<TData = unknown, TValue = unknown>(ctx: TanStackColumn<TData, TValue>): Column<TData, TValue> {
	return {
		id: ctx.id,
		Header: ctx.columnDef?.meta?.originalColumnDef.Header,
		filterValue: ctx.getFilterValue() as TValue,
		setFilter: ctx.setFilterValue,
		columnDef: ctx.columnDef?.meta?.originalColumnDef ?? {}
	};
}

export function getColumnHeaderProps<TData = unknown, TValue = unknown>(
	ctx: HeaderContext<TData, TValue>
): TableInstance<TData> & ColumnHeaderProps<TData, TValue> {
	return {
		rows: ctx.table.getRowModel().rows.map(getRowProps<TData>),
		className: ctx.column.columnDef.meta?.originalColumnDef.className,
		header: ctx.header,
		disabled: false,
		role: 'columnheader',
		dataAttributes: {},
		size: 'sm',
		isAllRowsSelected: ctx.table.getIsAllRowsSelected(),
		toggleAllRowsSelected: (val?: boolean) => ctx.table.toggleAllRowsSelected(val)
	};
}
