import React from 'react';
import { SortButton } from '../sortable/SortButton';
import { flexRender } from '@tanstack/react-table';
import { AriaSortOptions, ColumnHeaderProps, FilterProps } from '../types';
import { formatDataAttributes } from '../utility/formatDataAttribute';
import { getColumnProps, getRowProps } from '../utility/tableEntitiesConverters';

export const ColumnHeader = <TData = unknown, TValue = unknown>(headerProps: ColumnHeaderProps<TData, TValue>): JSX.Element => {
	const { header, disabled, loading, size, dataAttributes, isAllRowsSelected, toggleAllRowsSelected, ...props } = headerProps;

	let ariaSort: AriaSortOptions = undefined;
	const isSorted = header.column.getIsSorted();
	if (isSorted === 'desc') {
		ariaSort = 'descending';
	} else if (isSorted === 'asc') {
		ariaSort = 'ascending';
	}

	const columnDefMeta = header.column.columnDef.meta;
	const columnHasFilter = header.column.getCanFilter() && columnDefMeta?.originalColumnDef.Filter;

	const className = [props.className, columnDefMeta?.className].filter(Boolean).join(' ');

	const filterProps: FilterProps<TData, TValue> = {
		rows: header.getContext().table.getRowModel().rows.map(getRowProps),
		column: getColumnProps(header.column),
		readOnly: loading ?? false,
		disabled: disabled ?? false
	};

	return (
		<th aria-sort={ariaSort} {...props} className={className}>
			{header.column.getCanSort() ? (
				<SortButton
					header={header.getContext()}
					disabled={loading || disabled}
					className={size ? `btn-${size}` : ''}
					{...formatDataAttributes(dataAttributes, 'sort')}>
					{flexRender(header.column.columnDef.header, header.getContext())}
				</SortButton>
			) : (
				flexRender(header.column.columnDef.header, header.getContext())
			)}
			{columnHasFilter ? <div className="p-1">{columnDefMeta?.originalColumnDef.Filter?.(filterProps)}</div> : null}
		</th>
	);
};
