import React from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';
import { PreviousPageItem } from './PreviousPageItem';
import { NextPageItem } from './NextPageItem';
import { PaginationItems } from './PaginationItems';
import { LengthMenu } from './LengthMenu';
import { PaginationProps } from './types';

/**
 * - Features
 *  - number of table entries
 *  - number of rows per page
 *  - page navigation
 *  - Full react-table documentation can be found on their github page [usePagination api](https://github.com/tannerlinsley/react-table/blob/v7.0.4/docs/api/usePagination.md)
 */
export default function Pagination<TData = unknown>({
	rows,
	rowCount,
	pageCount = 1,
	pageIndex = 0,
	pageSize,
	canPreviousPage,
	canNextPage,
	setPageSize,
	gotoPage,
	previousPage,
	nextPage,
	menuOptions,
	lengthMenuProps,
	paginationProps
}: PaginationProps<TData>) {
	return (
		<div className="d-flex flex-wrap pb-3">
			<LengthMenu
				{...lengthMenuProps}
				menuOptions={menuOptions}
				value={pageSize}
				totalItems={rowCount ?? rows.length}
				onChange={e => {
					setPageSize(Number(e.target.value));
				}}
			/>
			{pageCount > 1 ? (
				<nav aria-label="Page Navigation" className="ms-auto justify-content-end align-items-end" {...paginationProps}>
					<BootstrapPagination bsPrefix="pagination m-0">
						<PreviousPageItem pageIndex={pageIndex} onClick={previousPage} />
						<PaginationItems
							pageIndex={pageIndex}
							pageCount={pageCount}
							onClick={gotoPage}
							canPreviousPage={canPreviousPage}
							canNextPage={canNextPage}
						/>
						<NextPageItem pageIndex={pageIndex} pageCount={pageCount} onClick={nextPage} />
					</BootstrapPagination>
				</nav>
			) : null}
		</div>
	);
}
