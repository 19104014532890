import React, { useEffect, useState } from 'react';
import { NavBarProps } from './types';
import { useResponsiveBreakpoint } from '../../helpers/hooks/useResponsiveBreakpoint';
import { classJoin } from '../../helpers';

export const NavBar = (props: NavBarProps) => {
	const { as: Component = 'nav', defaultExpanded, expand, fixed, sticky, role = Component === 'nav' ? undefined : 'navigation', ...otherProps } = props;

	const breakpointHook = useResponsiveBreakpoint();
	const [expanded, setExpanded] = useState(defaultExpanded);

	useEffect(() => {
		if (typeof expand !== 'string') return;
		const shouldExpand = breakpointHook.usedBreakpoints[expand] >= breakpointHook.currentBreakpointWidth;
		setExpanded(shouldExpand);
	}, [breakpointHook, expand]);

	const navBarClasses = classJoin(
		'navbar',
		typeof expand === 'string' && `navbar-expand-${expand}`,
		(expand === true || expanded) && 'navbar-expand',
		fixed && `fixed-${fixed}`,
		sticky && 'sticky-top'
	);

	return (
		<Component {...otherProps} className={navBarClasses} role={role}>
			{props.children}
		</Component>
	);
};
