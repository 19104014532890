import FilePreview from './FilePreview';
import Button from '../../button/Button';
import { removeFileUploadFiles } from './hooks/useFileUploadInternal';
import { IconDelete } from '@optic-delight/icons';
import React, { MouseEvent } from 'react';
import { FileUploadFilesListProps } from './types';
import { getFileExtension, removeFileExtension } from '../../../helpers';

const FileUploadFilesList = (props: FileUploadFilesListProps) => {
	const { isInvalid, fileListFileNameColumnText, fileListFileTypeColumnText, fileList, disabled, preview, dispatch, noFilesText } = props;

	const trClasses = ['align-middle', disabled && 'disabled'].filter(Boolean).join(' ');

	function onDeleteFile(e: MouseEvent<HTMLButtonElement>, file: File) {
		removeFileUploadFiles(dispatch, [file.name]);
		props.onDelete?.([file]);
	}

	return (
		<table
			className={`table mt-2 ${isInvalid ? 'is-invalid' : ''}`}
			style={{
				opacity: disabled ? '0.7' : undefined,
				cursor: disabled ? 'no-drop' : undefined
			}}>
			<thead>
				<tr>
					<th>{fileListFileNameColumnText ?? 'File name'}</th>
					<th>{fileListFileTypeColumnText ?? 'File type'}</th>
					<th className="table-col-actions" />
				</tr>
			</thead>

			<tbody>
				{fileList.length ? (
					fileList.map(file => {
						return (
							<tr key={file.name} className={trClasses}>
								<td>
									<div className="d-flex text-break align-items-center">
										{preview ? (
											<div className="flex-shrink-0" style={{ width: '100px' }}>
												<FilePreview file={file} />
											</div>
										) : null}

										{removeFileExtension(file.name)}
									</div>
								</td>
								<td>{getFileExtension(file.name)}</td>
								<td className="table-col-actions">
									<Button variant="danger" aria-label={'Delete ' + file.name} size="sm" onClick={e => onDeleteFile(e, file)}>
										<IconDelete />
									</Button>
								</td>
							</tr>
						);
					})
				) : (
					<tr>
						<td colSpan={3}>{noFilesText}</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};
FileUploadFilesList.displayName = 'FileUploadFilesList';

export default FileUploadFilesList;
