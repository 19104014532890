import React from 'react';
import { iconPrefix, supportedThemes } from '@optic-delight/icons';
import { Column } from '@tanstack/react-table';

interface IconSortableProps<TData = unknown, TValue = unknown> {
	column: Column<TData, TValue>;
}

export const sortable = {
	unsorted: {
		ariaLabel: 'unsorted',
		sortIcon: 'sort',
		color: 'text-black-50'
	},
	descending: {
		ariaLabel: 'sort descending',
		sortIcon: 'sort-down',
		color: 'text-black'
	},
	ascending: {
		ariaLabel: 'sorted ascending',
		sortIcon: 'sort-up',
		color: 'text-black'
	}
};

export function IconSortable<TData = unknown, TValue = unknown>({ column }: IconSortableProps<TData, TValue>) {
	let options = sortable.unsorted;
	const isSorted = column.getIsSorted();
	if (isSorted === 'desc') {
		options = sortable.descending;
	} else if (isSorted === 'asc') {
		options = sortable.ascending;
	}

	// needs BSC-61525 to be worked before @optic-delight/icons component(s) can be used here
	return <i aria-label={options.ariaLabel} className={`${iconPrefix}-${supportedThemes.solid} ${iconPrefix}-${options.sortIcon} ${options.color} ms-1`} />;
}
