export type PaginationArray = Array<number | '...'>;

const getRange = (start: number, end: number): PaginationArray => {
	return Array(end - start + 1)
		.fill(undefined, undefined, undefined)
		.map((v, i) => i + start);
};

const deltas = {
	zero_ellipsis: 7,
	one_ellipsis: 4,
	two_ellipsis: 2
};

// one based currentPage index
export const usePagination = (currentPage: number, pageCount: number): PaginationArray => {
	if (pageCount === 0) {
		return [1];
	}

	let delta;
	if (pageCount <= deltas.zero_ellipsis) {
		// delta === 7: [1 2 3 4 5 6 7]
		delta = deltas.zero_ellipsis;
	} else {
		// delta === 2: [1 ... 4 5 6 ... 10]
		// delta === 4: [1 2 3 4 5 ... 10]
		delta = currentPage > 4 && currentPage < pageCount - 3 ? deltas.two_ellipsis : deltas.one_ellipsis;
	}

	const range = {
		start: Math.round(currentPage - delta / 2),
		end: Math.round(currentPage + delta / 2)
	};

	if (range.start - 1 === 1 || range.end + 1 === pageCount) {
		range.start += 1;
		range.end += 1;
	}

	let pages =
		currentPage > delta ? getRange(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount)) : getRange(1, Math.min(pageCount, delta + 1));

	const withEllipsis = (value: number, pair: PaginationArray): PaginationArray => (pages.length + 1 !== pageCount ? pair : [value]);

	if (pages[0] !== 1) {
		pages = withEllipsis(1, [1, '...']).concat(pages);
	}

	const lastPaginationItem = pages[pages.length - 1];
	if (typeof lastPaginationItem === 'number' && lastPaginationItem < pageCount) {
		pages = pages.concat(withEllipsis(pageCount, ['...', pageCount]));
	}

	return pages;
};
