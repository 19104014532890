import * as React from 'react';
import { rowSpacer } from '../filters/utility';

export type ComponentArrayChildren = React.ReactElement | React.ReactElement[];

interface ComponentArrayProps {
	children: ComponentArrayChildren;
	readOnly?: boolean;
}

export default function ComponentArray({ children, readOnly }: ComponentArrayProps): JSX.Element {
	const childrenArray = Array.isArray(children) ? children : [children];
	const newChildren = childrenArray.map((child, index) => {
		const key = index + (child?.key || '');
		return (
			<span key={key} className={`${rowSpacer} me-1`}>
				{React.cloneElement(child, { readOnly })}
			</span>
		);
	});
	return <div className="d-flex flex-wrap">{newChildren}</div>;
}
