import React, { useState } from 'react';
import { UseDragAndDropData, UseDragAndDropProps } from '../types';

export default function useDragAndDrop(props: UseDragAndDropProps): UseDragAndDropData {
	const { multiple, disabled, fileUpload } = props;
	const [dropDepth, setDropDepth] = useState(0);
	const [inDropZone, setInDropZone] = useState(false);

	const handleDragEnter = (e: React.DragEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setDropDepth(dropDepth + 1);
		props.onDragEnter?.(e);
	};

	const handleDragLeave = (e: React.DragEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();

		const newDepth = dropDepth - 1;
		setDropDepth(newDepth);
		if (newDepth > 0) {
			return;
		}
		setInDropZone(false);
		props.onDragLeave?.(e);
	};

	const handleDragOver = (e: React.DragEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (!multiple && e.dataTransfer.items && e.dataTransfer.items.length > 1) {
			/*
			 * console.log("too many files");
			 * TODO add overlay with cross out circle to deny the drop
			 */
		}
		e.dataTransfer.dropEffect = 'copy';
		setInDropZone(true);
		props.onDragOver?.(e);
	};

	function addFiles(files: File[]): File[] {
		const newFiles = fileUpload.addFiles?.(files) || [];
		setDropDepth(0);
		setInDropZone(false);
		return newFiles;
	}

	const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();

		const filteredFiles = addFiles([...e.dataTransfer.files]);
		props.onDrop?.(e, filteredFiles);
		return filteredFiles;
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();

		const filteredFiles = addFiles([...(e.target.files ?? [])]);
		props.onChange?.(e, filteredFiles);
		e.target.value = '';
	};

	const getStyle = (): React.CSSProperties => {
		return {
			padding: '3rem 2rem',
			border: '2px dashed #d7dce5',
			textAlign: 'center',
			backgroundColor: inDropZone ? '#F6F7F9' : undefined,
			cursor: disabled ? 'no-drop' : undefined,
			opacity: inDropZone || disabled ? '0.7' : undefined
		};
	};

	return {
		dropDepth,
		setDropDepth,
		inDropZone,
		setInDropZone,
		handleDragEnter,
		handleDragLeave,
		handleDragOver,
		handleDrop,
		handleChange,
		getStyle
	};
}
