import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { IconClose, IconSearch } from '@optic-delight/icons';
import { useTranslatableText } from '../../../translatable-text';
import { GlobalFilterProps } from '../types';
import { typingDebounceRate } from '../../utility/constants';

export const GlobalFilterDefaultAriaLabel = 'Search Table';

/**
 * - Searching each table column is enabled by default
 * - Full react-table documentation can be found on their GitHub page [useGlobalFilter api](https://github.com/tannerlinsley/react-table/blob/v7.0.4/docs/api/useGlobalFilter.md)
 */
export default function GlobalFilter({
	value: globalFilter = '',
	onChange: setGlobalFilter,
	'aria-label': ariaLabel = GlobalFilterDefaultAriaLabel,
	...props
}: GlobalFilterProps) {
	const [value, setValue] = React.useState<string>(globalFilter);
	const { globalFilterPlaceholder: placeholder } = useTranslatableText();

	const handleChange = (newValue: string) => {
		setValue(newValue);
	};

	React.useEffect(() => {
		setValue(globalFilter);
	}, [globalFilter]);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setGlobalFilter(value?.trim() || undefined);
		}, typingDebounceRate);

		return () => clearTimeout(timeout);
	}, [setGlobalFilter, value]);

	return (
		<InputGroup className="input-group-embedded">
			<InputGroup.Text>
				<IconSearch />
			</InputGroup.Text>
			<FormControl
				type="search"
				aria-label={ariaLabel}
				placeholder={placeholder}
				value={(value as string) || ''}
				onChange={e => handleChange(e.target.value)}
				{...props}
			/>
			<InputGroup.Text as="button" type="button" aria-label="clear" onClick={() => handleChange('')}>
				<IconClose />
			</InputGroup.Text>
		</InputGroup>
	);
}

export const GlobalFilterAriaLabel = 'Search Table';
