import React from 'react';
import { Table } from 'react-bootstrap';
import Pagination from './pagination/Pagination';
import { HorizontalTable, VerticalTable } from './render-mode';
import { TableFilters } from './filters/TableFilters';
import { isColumnEmpty } from './utility';
import { FilterContext } from './utility/FilterContext';
import { InternalDatatableProps } from './types';
import { TranslatableTextProvider } from '../translatable-text';
import { useReactTable } from '@tanstack/react-table';
import { TableFiltersProps } from './filters';
import { getRowProps } from './utility/tableEntitiesConverters';

export default function InternalDatatable<TData = unknown, TValue = unknown>(props: InternalDatatableProps<TData, TValue>) {
	const { fetch, filteredRowCount, rowCount, ...tableOptions } = props.useTableOptions ?? {};
	const { onSelectedRows } = props;

	const table = useReactTable<TData>({
		...props.tanstackTableProps,
		...tableOptions
	});
	const headerGroups = table.getHeaderGroups();
	const footerGroups = table.getFooterGroups();
	const rowModel = table.getRowModel();
	const tableState = table.getState();

	const groupsWithDefinedHeaders = headerGroups.filter(headerGroup => {
		return headerGroup.headers.some(item => !isColumnEmpty(item.column.columnDef, 'header'));
	});
	const isPresentationTable = props.role === 'presentation' || groupsWithDefinedHeaders.length === 0;

	const filterProps: TableFiltersProps<TData> = {
		globalFilterEnabled: props.tanstackTableProps.enableGlobalFilter,
		globalFilter: tableState.globalFilter,
		globalFilterProps: {
			...props.globalFilterProps,
			value: tableState.globalFilter,
			onChange: table.setGlobalFilter
		},
		customFilters: props.customFilters,
		filterLabelsMapping: props.filterLabelsMapping,
		actionButtons: props.actionButtons,
		useTableColumns: table.getAllColumns(),
		loading: props.loading ?? false,
		rowCount: filteredRowCount ?? rowCount ?? rowModel.rows.length
	};

	React.useEffect(() => {
		fetch?.({
			pageIndex: tableState.pagination.pageIndex,
			pageSize: tableState.pagination.pageSize,
			globalFilter: tableState.globalFilter,
			filters: tableState.columnFilters,
			sortBy: tableState.sorting
		});
	}, [fetch, tableState.pagination.pageIndex, tableState.pagination.pageSize, tableState.columnFilters, tableState.globalFilter, tableState.sorting]);

	React.useEffect(() => {
		const rows = table.getSelectedRowModel().rows.map(getRowProps);
		onSelectedRows?.(rows);
	}, [table, onSelectedRows]);

	return (
		<div className="ui-datatable-wrapper">
			<TranslatableTextProvider value={props.translatableProps ?? {}}>
				<FilterContext.Provider value={{ onFilterClear: props.onFilterClear } as never}>
					<TableFilters {...filterProps} />

					<Table
						className={props.className}
						role={isPresentationTable ? 'presentation' : 'table'}
						striped={props.striped}
						size={props.size}
						responsive={props.responsive}
						bordered={props.bordered}
						borderless={props.borderless}
						data-testid={props['data-testid']}>
						{typeof props.caption === 'string' ? <caption>{props.caption}</caption> : props.caption}
						{props.type === 'vertical' ? (
							<VerticalTable<TData>
								headerGroups={headerGroups}
								getTableBodyProps={props.getTableBodyProps}
								isPresentationTable={isPresentationTable}
								paginationEnabled={!!tableState.pagination}
								rows={rowModel.rows}
								page={rowModel.rows}
								renderRowSubComponent={props.subRow}
								loading={props.loading}
								size={props.size}
							/>
						) : (
							<HorizontalTable rows={rowModel.rows} headerGroups={headerGroups} footerGroups={footerGroups} />
						)}
					</Table>
				</FilterContext.Provider>

				{!props.loading && props.paginationEnabled ? (
					<Pagination<TData>
						rows={rowModel.rows}
						rowCount={Object.keys(rowModel.rowsById).length}
						pageCount={table.getPageCount()}
						pageIndex={table.getState().pagination.pageIndex}
						pageSize={table.getState().pagination.pageSize}
						canPreviousPage={table.getCanPreviousPage()}
						canNextPage={table.getCanNextPage()}
						setPageSize={table.setPageSize}
						gotoPage={table.setPageIndex}
						previousPage={table.previousPage}
						nextPage={table.nextPage}
						menuOptions={props.lengthMenu}
						lengthMenuProps={props.lengthMenuProps}
						paginationProps={props.paginationProps}
					/>
				) : null}
			</TranslatableTextProvider>
		</div>
	);
}
