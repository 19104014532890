import { UserInterfaceTypeResolver } from '../types';
import { Nav, NavBar } from '../../components';
import { childrenOrTitleAdapter } from '../adapter/children-or-title';

export const navResolver: UserInterfaceTypeResolver = (options, processor) => {
	const { contract, level, parent } = options;

	switch (contract.type) {
		case 'nav':
			return [
				Nav,
				{
					children: childrenOrTitleAdapter(options, processor)
				}
			];
		case 'nav-item':
			if (contract.link) {
				return [
					Nav.Item,
					{
						children: processor.process({
							contract: {
								type: 'nav-link',
								link: contract.link,
								title: contract.title
							},
							level: level + 1,
							parent
						})
					}
				];
			}

			return [
				Nav.Item,
				{
					children: childrenOrTitleAdapter(options, processor)
				}
			];
		case 'nav-link':
			return [
				Nav.Link,
				{
					children: childrenOrTitleAdapter(options, processor),
					href: contract.link
				}
			];
		case 'navbar':
			return [
				NavBar,
				{
					children: childrenOrTitleAdapter(options, processor)
				}
			];
		default:
			return [];
	}
};
