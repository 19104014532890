import React from 'react';
import { useRowSelectionColumn } from '../row-selection/hooks/useRowSelectionColumn';
import { ColumnDef as TanStackColumnDef } from '@tanstack/table-core/build/lib/types';
import { CellContext, HeaderContext, Row } from '@tanstack/react-table';
import { ColumnDef, UseColumnsData, UseColumnsProps } from '../types';
import { getCellProps, getColumnHeaderProps, getRowProps } from '../utility/tableEntitiesConverters';

export const useColumns = <TData, TValue>({
	sortable,
	columns,
	rowSelection,
	rowSelectionOptions,
	expandableColumn,
	expandableColumnClassName
}: UseColumnsProps<TData, TValue>): UseColumnsData<TData, TValue> => {
	const { rowSelection: innerRowSelection = rowSelection, ...innerRowSelectionOptions } = rowSelectionOptions ?? {};

	if (sortable !== undefined && !sortable) {
		columns?.map(column => (column.enableSorting = true));
	}

	const getHeaderDefinition = (col: ColumnDef<TData, TValue>) => {
		if (!col.Header) return undefined;
		if (typeof col.Header === 'string') return col.Header;
		return (ctx: HeaderContext<TData, TValue>) => (typeof col.Header === 'string' ? col.Header : col.Header?.(getColumnHeaderProps(ctx)));
	};

	const getFooterDefinition = (col: ColumnDef<TData, TValue>) => {
		if (!col.Footer) return undefined;
		if (typeof col.Footer === 'string') return col.Footer;
		return (ctx: HeaderContext<TData, TValue>) => (typeof col.Footer === 'string' ? col.Footer : col.Footer?.(getColumnHeaderProps(ctx)));
	};

	const formattedColumns: TanStackColumnDef<TData, TValue>[] =
		columns?.map(col => {
			const columnDef: TanStackColumnDef<TData, TValue> = {
				id: col.id,
				accessorKey: col.accessor ?? '',
				header: getHeaderDefinition(col),
				cell: (info: CellContext<TData, TValue>) => (col.Cell ? col.Cell(getCellProps(info)) : info.getValue()),
				footer: getFooterDefinition(col),
				filterFn: col.filter
					? (row: Row<TData>, columnId, filterValue) => {
							return col.filter?.(getRowProps(row), columnId as keyof TData, filterValue) ?? true;
					  }
					: `includesString`,
				enableColumnFilter: !!col.Filter,
				enableGlobalFilter: !col.disableGlobalFilter,
				meta: {
					className: col.className,
					style: col.style,
					dataAttributes: col.dataAttributes,
					originalColumnDef: col
				}
			};
			return columnDef;
		}) ?? [];

	const originalColumnDefs = [...(columns ?? [])];

	if (expandableColumn) {
		formattedColumns.unshift({
			...expandableColumn,
			meta: {
				...expandableColumn.meta,
				originalColumnDef: {
					id: expandableColumn.id,
					Header: () => <>{expandableColumn.header}</>,
					className: expandableColumnClassName
				}
			}
		});
	}

	if (innerRowSelection) {
		const selectionColumn = useRowSelectionColumn<TData, TValue>(innerRowSelectionOptions);
		formattedColumns.unshift({
			id: selectionColumn.id,
			accessorKey: '',
			header: getHeaderDefinition(selectionColumn),
			cell: (info: CellContext<TData, TValue>) => (selectionColumn.Cell ? selectionColumn.Cell(getCellProps(info)) : info.getValue()),
			meta: {
				originalColumnDef: selectionColumn
			}
		});
	}

	return {
		innerRowSelection,
		formattedColumns,
		originalColumnDefs
	};
};
