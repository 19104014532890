interface ProcessPropsData {
	enabled: boolean;
	props: Record<string, unknown>;
}

type TupleOfSameLengthArrays = [Array<number>, Array<string | number>];
interface ProcessLengthMenuPropsData {
	menuOptions: TupleOfSameLengthArrays;
	props: Record<string, unknown>;
}

export function processGlobalFilterProps(initialProp?: Record<string, unknown> | boolean): ProcessPropsData {
	const enabled = !!(initialProp === undefined || initialProp);
	const props = initialProp && typeof initialProp === 'object' ? initialProp : {};

	return {
		enabled,
		props
	};
}

export function processPaginationProps(initialProp?: Record<string, unknown> | boolean): ProcessPropsData {
	const enabled = !!(initialProp === undefined || initialProp);
	const props = initialProp && typeof initialProp === 'object' ? initialProp : {};

	return {
		enabled,
		props
	};
}

export function processLengthMenuProps(
	initialProp?: (Record<string, unknown> & { menuOptions: TupleOfSameLengthArrays }) | TupleOfSameLengthArrays,
	hiddenPagination = false
): ProcessLengthMenuPropsData {
	const hasLengthMenuProps = initialProp && typeof initialProp === 'object' && !Array.isArray(initialProp);
	const { menuOptions: menuOptionsFromProps = undefined, ...props } = hasLengthMenuProps ? initialProp : {};
	let menuOptions = (hasLengthMenuProps ? menuOptionsFromProps : initialProp) as TupleOfSameLengthArrays;

	if (hiddenPagination) {
		menuOptions = [[-1], ['all']]; // force all rows to display
	}

	return {
		menuOptions,
		props
	};
}
