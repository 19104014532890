import { DataAttributesProp } from '../types';

const formatDataAttributeKey = (attribute: string): string => {
	const splitAttr = attribute.split('-').filter(a => Boolean(a.trim()));

	if (!splitAttr.length || (splitAttr.length === 1 && splitAttr.includes('data'))) {
		return '';
	}

	if (splitAttr.length) {
		const dataIndex = splitAttr.indexOf('data');

		if (dataIndex > -1) {
			splitAttr.splice(dataIndex, 1);
		}
	}

	return `data-${splitAttr.join('-')}`;
};

export const formatDataAttributes = (attributes?: DataAttributesProp, attributeSuffix?: string): DataAttributesProp | undefined => {
	if (attributes === undefined || !Object.keys(attributes).length) return;

	const formattedResult: DataAttributesProp = {};

	for (const key in attributes) {
		const transformedKey = formatDataAttributeKey(key) + (attributeSuffix ? `-${attributeSuffix}` : '');
		formattedResult[transformedKey] = attributes[key];
	}

	return formattedResult;
};
