import { ChangeEvent, Dispatch, useEffect, useReducer, useRef } from 'react';
import { EMPTY_ARRAY } from '../utility/constants';
import { addFiles, removeFiles, replaceFiles, addErrors, removeErrors } from '../fileUploadActions';
import { fileUploadReducer } from '../fileUploadReducer';
import { FileUploadData, FileUploadDataReducerActionInternal, FileUploadReducerInternal, UseFileUploadInternalData, UseFileUploadProps } from '../types';
import { getFileExtension, noop } from '../../../../helpers';
import { useFormContext } from '../../form-element';
import { createSyntheticEvent } from '../../../../helpers/createSyntheticEvent';

export default function useFileUploadInternal(props: UseFileUploadProps): UseFileUploadInternalData {
	const firstUpdate = useRef(true);

	const { name, required = false, defaultValue = EMPTY_ARRAY, multiple, disabled, blackList, accept, maxSize, dirty = false, errorMessages } = props;
	const triggerRef = useRef(dirty);
	const initialValueRef = useRef(defaultValue);

	const useReducerData: FileUploadData = {
		fileList: initialValueRef.current,
		showErrorAlert: false,
		errorList: EMPTY_ARRAY,
		isInvalid: dirty && required,
		required: required
	};

	const combinedReducer: FileUploadReducerInternal = (state, action) => {
		const basicChanges = fileUploadReducer(state, action);
		return props.stateReducer?.(state, { type: action.type, changes: basicChanges }) ?? basicChanges;
	};

	const [data, dispatch] = useReducer(combinedReducer, useReducerData);
	const { formState, register, unregister, setValue, trigger } = useFormContext();
	const isDisabled = formState?.isDisabled || disabled;

	useEffect(() => {
		if (register) {
			register(name || '', {
				required: data.required,
				value: isDisabled ? undefined : initialValueRef.current,
				disabled: isDisabled,
				validate: value => {
					return (value === undefined || Array.isArray(value)) && (!data.required || (data.required && value?.length > 0));
				}
			});

			return () => {
				unregister(name);
			};
		}
	}, [register, unregister, name, isDisabled, data.required, initialValueRef]);

	useEffect(() => {
		if (triggerRef.current) {
			if (typeof register !== 'undefined' && !isDisabled) {
				setValue(name || '', data.fileList);
				trigger(name).catch(noop);
			}
		} else {
			triggerRef.current = true;
		}
	}, [register, setValue, trigger, name, isDisabled, data.fileList]);

	/*
	 * TODO mutate list by mime-type. ex) .mp3 vs audio/mpeg
	 * TODO mutate list by regex 	 ex) .mp3 vs audio/mpeg vs audio/*
	 */
	const mutateFilesByType = (files: File[], evaluationTypes?: string | string[], inclusion?: boolean): File[] => {
		if (files && evaluationTypes && evaluationTypes.length > 0) {
			let evaluateList = evaluationTypes;
			if (!Array.isArray(evaluateList)) {
				evaluateList = (evaluationTypes as string).split(',');
			}

			// remove/retain files by extension
			files = files.filter((file: File) => {
				const extension = '.' + getFileExtension(file.name);
				const isValid = inclusion ? evaluateList.includes(extension) : !evaluateList.includes(extension);
				if (!isValid) {
					dispatch(addErrors([`${file.name} ${errorMessages.disallowedFileType}`]));
				}
				return isValid;
			});
		}

		return files;
	};

	const mutateFilesBySize = (files: File[]) => {
		if (files && maxSize) {
			files = files.filter(file => {
				if (file.size === 0) {
					dispatch(addErrors([`${file.name} ${errorMessages.fileEmpty}`]));
					return false;
				}

				const isValid = file.size <= maxSize;
				if (!isValid) {
					dispatch(addErrors([`${file.name} ${errorMessages.fileTooLarge}`]));
				}
				return isValid;
			});
		}

		return files;
	};

	const mutateFilesByPreExisting = (files: File[]) => {
		const existingFiles = data.fileList.map((file: File) => file.name.toLowerCase());
		return files.filter(file => {
			const isValid = !existingFiles.includes(file.name.toLowerCase());
			if (!isValid) {
				dispatch(addErrors([`${file.name} ${errorMessages.duplicateFile}`]));
			}
			return isValid;
		});
	};

	const mutateFilesByValidation = (files: File[]) => {
		if (props.validators.length === 0) return files;

		return files.filter(file => {
			const errorMessages = props.validators
				.map(validator => {
					if (validator.validate(file)) return false;
					return validator.message.replace('%s', file.name);
				})
				.filter(Boolean) as string[];

			if (errorMessages.length > 0) {
				dispatch(addErrors(errorMessages));
				return false;
			}

			return true;
		});
	};

	const removeInvalidFiles = (files: File[]): File[] => {
		if (isDisabled) {
			return EMPTY_ARRAY;
		} else if (!multiple && files && files.length > 1) {
			dispatch(addErrors([errorMessages.tooManyFiles]));
			return EMPTY_ARRAY;
		}

		files = mutateFilesByType(files, blackList, false);
		files = mutateFilesByType(files, accept, true);
		files = mutateFilesBySize(files);
		files = mutateFilesByPreExisting(files);
		files = mutateFilesByValidation(files);

		return files;
	};

	const addFileUploadFiles = (files: File[]): File[] => {
		dispatch(removeErrors());
		const newFiles = removeInvalidFiles(files);
		if (newFiles && newFiles.length > 0) {
			if (!multiple && newFiles.length === 1) {
				dispatch(replaceFiles(newFiles));
			} else {
				dispatch(addFiles(newFiles));
			}
		}
		return newFiles;
	};

	const dispatchRemoveFiles = (filenames: string[]) => {
		dispatch(removeFiles(filenames));
	};

	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		const event = new Event('change', { bubbles: true });
		Object.defineProperty(event, 'target', { writable: false, value: null });
		const syntheticEvent = createSyntheticEvent(event) as ChangeEvent<HTMLInputElement>;
		props.onChange?.(syntheticEvent, data.fileList);
	}, [data.fileList]);

	return {
		formState,
		data,
		dispatch,
		addFiles: addFileUploadFiles,
		removeFiles: dispatchRemoveFiles
	};
}

export function removeFileUploadErrors(dispatch: Dispatch<FileUploadDataReducerActionInternal<string | File>>) {
	dispatch(removeErrors());
}

export function removeFileUploadFiles(dispatch: Dispatch<FileUploadDataReducerActionInternal<string | File>>, files: string[]) {
	dispatch(removeFiles(files));
}
