import React from 'react';
import { isColumnEmpty } from '../utility';
import { Cell, flexRender, Header, Row } from '@tanstack/react-table';
import { HorizontalTableProps } from './types';

function HorizontalTable<TData = unknown, TValue = unknown>(props: HorizontalTableProps<TData>) {
	const { headerGroups, footerGroups, rows } = props;

	const visibleHeaders = headerGroups.flatMap(group => {
		return group.headers.filter((header: Header<TData, unknown>) => !isColumnEmpty(header.column.columnDef, 'header'));
	}) as Header<TData, TValue>[];

	const rowValue = React.useCallback(
		(header: Header<TData, TValue>, attrs: Record<string, string> = {}) => {
			return rows.map((row: Row<TData>) => {
				return (row.getVisibleCells() as Cell<TData, TValue>[])
					.filter(cell => cell.column.id === header.id)
					.map(cell => {
						const cellDefinition = cell.column.columnDef.cell;
						const renderedCell = typeof cellDefinition === 'string' ? cellDefinition : cellDefinition?.(cell.getContext());

						// need to call toString, so zero value won't be hidden
						return (
							<td key={'column-value:' + header.id} className="text-end" {...attrs}>
								{flexRender(renderedCell?.toString(), cell.getContext())}
							</td>
						);
					});
			});
		},
		[rows]
	);

	return (
		<>
			<tbody data-testid="horizontal">
				{visibleHeaders.map(header => (
					<tr key={header.id}>
						<th scope="col">{flexRender(header.column.columnDef.header, header.getContext())}</th>
						{rowValue(header)}
					</tr>
				))}
			</tbody>

			{footerGroups.map((group, index: number) => (
				<tfoot key={index}>
					{(group.headers as Header<TData, TValue>[])
						.filter(header => !isColumnEmpty(header.column.columnDef, 'footer'))
						.map(header => {
							return (
								<tr key={header.id}>
									<th>{flexRender(header.column.columnDef.footer, header.getContext())}</th>
									{rowValue(header, { 'data-testid': 'footer-sum' })}
								</tr>
							);
						})}
				</tfoot>
			))}
		</>
	);
}

export default HorizontalTable;
