import React from 'react';
import { Input } from '@optic-delight/ui-framework';
import { typingDebounceRate } from '../../utility/constants';
import { FilterProps } from '../../types';

export default function TextFilter<TData = unknown, TValue = unknown>({ column, ...props }: FilterProps<TData, TValue>) {
	const filterValue = column.filterValue as string;
	const [value, setValue] = React.useState(filterValue);

	const mountedRef = React.useRef(false);

	React.useEffect(() => {
		if (!mountedRef.current) {
			mountedRef.current = true;
			return;
		}
		setValue(filterValue);
	}, [mountedRef, filterValue]);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			column.setFilter(value?.trim() || undefined);
		}, typingDebounceRate);

		return () => clearTimeout(timeout);

		// we ignore `column` dependency as we cannot be sure users memoize the object
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<Input
			aria-label={'Filter ' + column.Header}
			type="text"
			value={value || ''}
			onChange={e => setValue((e.target as HTMLInputElement).value)}
			groupClassName="mb-0"
			{...props}
		/>
	);
}
