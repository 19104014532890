export const APPLICATION_ROOT = 'bsc-react-root';
export const DEFAULT_QUEUE_INTERVAL = 10000;

export const MessageType = {
	KEEP_ALIVE: 'keepAlive',
	ROUTE_CHANGE: 'analyticsRouteChange'
};

export const Company = {
	System: 0,
	SuperTest: 7426
};
