import React from 'react';

import { ColumnHeader } from '../components/ColumnHeader';
import { ExpressionWrapper, isColumnEmpty } from '../utility';

import { IconLoading } from '@optic-delight/icons';
import { useTranslatableText } from '../../translatable-text';
import { formatDataAttributes } from '../utility/formatDataAttribute';
import { ColumnHeaderProps, VerticalTableProps } from '../types';
import { flexRender } from '@tanstack/react-table';
import { getRowProps } from '../utility/tableEntitiesConverters';

const VerticalTable = <TData = unknown>({
	headerGroups,
	isPresentationTable,
	getTableBodyProps,
	paginationEnabled,
	rows,
	page,
	renderRowSubComponent,
	loading = false,
	disabled = false
}: VerticalTableProps<TData>) => {
	const hasVisibleHeaders =
		headerGroups.filter(headerGroup => {
			return headerGroup.headers.some(item => !isColumnEmpty(item.column.columnDef, 'header'));
		}).length > 0;
	const { noResultsText, loadingText } = useTranslatableText();

	return (
		<>
			{hasVisibleHeaders ? (
				<thead>
					{headerGroups.map(headerGroup => {
						return (
							<tr key={headerGroup.id} role={isPresentationTable ? undefined : 'row'}>
								{headerGroup.headers.map(header => {
									const headerCtx = header.getContext();
									const originalColumn = header.column.columnDef.meta?.originalColumnDef;
									const headerProps: ColumnHeaderProps<TData> = {
										className: [header.column.getCanSort() && 'table-col-sortable', originalColumn?.className].filter(Boolean).join(' '),
										header,
										disabled,
										loading: loading,
										role: isPresentationTable ? undefined : 'columnheader',
										dataAttributes: formatDataAttributes(header.column.columnDef.meta?.dataAttributes),
										isAllRowsSelected: headerCtx.table.getIsAllRowsSelected(),
										toggleAllRowsSelected: headerCtx.table.toggleAllRowsSelected
									};

									return <ColumnHeader key={header.id} {...headerProps} />;
								})}
							</tr>
						);
					})}
				</thead>
			) : null}
			<tbody {...getTableBodyProps?.({})} role={isPresentationTable ? undefined : 'rowgroup'}>
				{(paginationEnabled ? page ?? [] : rows ?? []).map(row => {
					return (
						<React.Fragment key={row.id}>
							<tr role={isPresentationTable ? undefined : 'row'}>
								{row.getAllCells().map(cell => {
									const meta = cell.column.columnDef.meta;
									const cellDefinition = cell.column.columnDef.cell;
									const renderedCell = typeof cellDefinition === 'string' ? cellDefinition : cellDefinition?.(cell.getContext());

									const cellProps = {
										className: meta?.className,
										style: meta?.style,
										role: isPresentationTable ? undefined : 'cell'
									};

									// This expression for all type of table (expandable, with actions).
									return (
										<td key={cell.id} {...cellProps}>
											{flexRender(renderedCell, cell.getContext())}
										</td>
									);
								})}
							</tr>
							{row.getIsExpanded() && renderRowSubComponent ? (
								<tr id={`rowExpander${row.id}`} aria-live="polite" role={isPresentationTable ? undefined : 'row'}>
									<td colSpan={row.getVisibleCells().length}>
										{/*
                                        Inside it, call our renderRowSubComponent function. In reality,
                                        you could pass whatever you want as props to
                                        a component like this, including the entire
                                        table instance. But for this example, we'll just
                                        pass the row
                                    */}
										<div role="region">{renderRowSubComponent({ row: getRowProps(row) })}</div>
									</td>
								</tr>
							) : null}
						</React.Fragment>
					);
				})}
				{headerGroups.map(headerGroup => {
					return (
						<ExpressionWrapper
							key={headerGroup.id}
							expression={!!rows.length}
							colSpan={headerGroup.headers.length}
							isPresentationTable={isPresentationTable}>
							{loading ? (
								<>
									<IconLoading className="me-2" />
									{loadingText}
								</>
							) : (
								noResultsText
							)}
						</ExpressionWrapper>
					);
				})}
			</tbody>
		</>
	);
};

export default VerticalTable;
