import React, { useEffect } from 'react';
import { DatatableProps } from './types';
import InternalDatatable from './InternalDatatable';
import {
	ExpandedState,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	TableOptions
} from '@tanstack/react-table';
import { useColumns } from './hooks/useColumns';
import { UseTranslatableTextData } from '../translatable-text';
import { processGlobalFilterProps, processLengthMenuProps, processPaginationProps } from './utility/helpers';

// if datatable is in the loading state, it creates an empty array every time
// so that it does not create a new array we took it out of the component
const emptyArray: unknown[] = [];
const emptyObject = {};

/**
 * - Use Datatable when enhanced table functionality is needed
 * - Features
 *  - Table Filter
 *  - Column sort
 *  - Pagination
 */
export default function Datatable<TData = unknown, TValue = unknown>(datatableProps: DatatableProps<TData, TValue>) {
	const {
		className = '',
		columns = [],
		tableFilter = true,
		sortable = true,
		striped = false,
		paginate = true,
		responsive = true,
		initialSort = [],
		rowSelection = false,
		rowSelectionOptions,
		selectedRows = emptyObject,
		type = 'vertical',
		loading = false,
		lengthMenu,
		...props
	} = datatableProps;

	const [sorting, setSorting] = React.useState<SortingState>(initialSort);
	const [expanded, setExpanded] = React.useState<ExpandedState>({});
	const [rowSelectionState, setRowSelectionState] = React.useState(selectedRows);

	const tableClassNames = [className, type === 'horizontal' && 'table-horizontal'].filter(Boolean).join(' ');
	const { enabled: globalFilterEnabled, props: globalFilterProps } = processGlobalFilterProps(tableFilter);

	const { subRow = props.renderRowSubComponent, column: expandableColumn, className: expandableColumnClassName } = props.rowExpansionOptions ?? {};

	const { innerRowSelection, formattedColumns } = useColumns<TData, TValue>({
		sortable,
		columns,
		rowSelection,
		rowSelectionOptions,
		expandableColumn,
		expandableColumnClassName: expandableColumnClassName ?? ''
	});

	// useRowSelect requires usePagination hook to be in use.  Internally, we will suppress displaying paginated items
	const hiddenPagination = innerRowSelection && !paginate;
	const { menuOptions, props: lengthMenuProps } = processLengthMenuProps(lengthMenu, hiddenPagination);
	const { enabled: paginationEnabled, props: paginationProps } = processPaginationProps(paginate);

	const tanstackTableProps: TableOptions<TData> = {
		data: loading || !props.data ? (emptyArray as TData[]) : props.data,
		columns: formattedColumns,

		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: menuOptions ? menuOptions[0][0] : 25
			},
			columnFilters: props.initialFilteredColumns ?? []
		},

		state: {
			sorting,
			expanded,
			rowSelection: rowSelectionState
		},

		enableGlobalFilter: globalFilterEnabled,
		enableFilters: globalFilterEnabled && Boolean(tableFilter),
		enableExpanding: !!subRow,
		enableRowSelection: innerRowSelection,
		enableSorting: sortable,

		onSortingChange: setSorting,
		onExpandedChange: setExpanded,
		onRowSelectionChange: setRowSelectionState,

		// canExpand is true only when there are subrows, so we need to set it manually to true to use
		// custom component to render as a subrow
		getRowCanExpand: () => !!subRow,

		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel()
	};

	const translatableTextProps: UseTranslatableTextData = {
		globalFilterPlaceholder: props.globalFilterPlaceholder,
		filterResultsLabel: props.filterResultsLabel,
		loadingText: props.loadingText,
		noResultsText: props.noResultsText,
		searchResultsText: props.searchResultsText,
		filterResultsText: props.filterResultsText,
		lengthMenuShowText: props.lengthMenuShowText,
		lengthMenuOfText: props.lengthMenuOfText,
		clearAllText: props.clearAllText
	};

	useEffect(() => {
		setRowSelectionState(selectedRows);
	}, [selectedRows]);

	return (
		<InternalDatatable<TData, TValue>
			className={tableClassNames}
			role={props.role}
			loading={loading}
			type={type}
			lengthMenu={menuOptions}
			translatableProps={translatableTextProps}
			caption={props.caption}
			useTableOptions={props.useTableOptions}
			getTableBodyProps={props.getTableBodyProps}
			actionButtons={props.actionButtons}
			subRow={subRow}
			customFilters={props.customFilters}
			filterLabelsMapping={props.filterLabelsMapping}
			onFilterClear={props.onFilterClear}
			paginate={paginate}
			globalFilterProps={globalFilterProps}
			lengthMenuProps={lengthMenuProps}
			rowSelectionState={rowSelectionState}
			striped={striped}
			size={props.size}
			responsive={responsive}
			bordered={props.bordered}
			borderless={props.borderless}
			data-testid={props['data-testid']}
			onSelectedRows={props.onSelectedRows}
			paginationEnabled={paginationEnabled}
			paginationProps={paginationProps}
			tanstackTableProps={tanstackTableProps}
		/>
	);
}
