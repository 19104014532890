import React from 'react';
import { UseResponsiveBreakpointProps } from '../types';
import { debounce } from '../utility';

const defaultBreakpoints = {
	xxl: 1140,
	xl: 960,
	lg: 720,
	md: 540,
	sm: 0
};

export const useResponsiveBreakpoint = (props?: UseResponsiveBreakpointProps) => {
	const { breakpoints = defaultBreakpoints } = props ?? {};

	// let xs be always the smallest no matter what (until there is another breakpoint with zero value)
	const breakpointEntries = Object.entries({ ...breakpoints, xs: 0 });
	const sortedBreakpoints = breakpointEntries.sort((a, b) => b[1] - a[1]);
	const usedBreakpoints = Object.fromEntries(sortedBreakpoints);

	const determineBreakpoint = React.useCallback(() => {
		for (const [breakpointName, breakpointWidth] of sortedBreakpoints) {
			if (window.innerWidth > breakpointWidth) {
				return breakpointName;
			}
		}
		return 'xs';
	}, [breakpoints]);

	const [breakpoint, setBreakpoint] = React.useState(determineBreakpoint);

	React.useLayoutEffect(() => {
		function handleBreakpointChange() {
			const resizedBreakpoint = determineBreakpoint();
			setBreakpoint(currentBreakpoint => {
				if (currentBreakpoint !== resizedBreakpoint) {
					return resizedBreakpoint;
				}
				return currentBreakpoint;
			});
		}
		const debouncedResizeHandler = debounce(handleBreakpointChange, 100);

		window.addEventListener('resize', debouncedResizeHandler);
		return () => window.removeEventListener('resize', debouncedResizeHandler);
	}, [determineBreakpoint, setBreakpoint]);

	return {
		currentBreakpoint: breakpoint,
		currentBreakpointWidth: usedBreakpoints[breakpoint],
		usedBreakpoints
	};
};
