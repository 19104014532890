import { ColumnDef, noop } from '@tanstack/react-table';

export function isColumnEmpty<TData = unknown, TValue = unknown>(column: ColumnDef<TData, TValue>, key: 'header' | 'footer'): boolean {
	return (
		!Object.prototype.hasOwnProperty.call(column, key) ||
		(typeof column[key] === 'string' && String(column[key]).length === 0) ||
		column[key] === undefined ||
		column[key] === noop
	);
}
