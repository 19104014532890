import React from 'react';
import { Pagination, PaginationProps } from 'react-bootstrap';
import { IconNext } from '@optic-delight/icons';

interface NextPageItemProps extends PaginationProps {
	pageIndex: number;
	pageCount: number;
}
export function NextPageItem({ pageIndex, pageCount, ...props }: NextPageItemProps) {
	return pageIndex + 1 < pageCount ? (
		<Pagination.Next {...props}>
			Next <IconNext />
		</Pagination.Next>
	) : null;
}
