import React from 'react';
import { NavItemProps } from './types';

export const NavItem = (props: NavItemProps) => {
	const { as: Component = 'div', ...otherProps } = props;

	return (
		<Component {...otherProps} className="nav-item">
			{props.children}
		</Component>
	);
};
