import React from 'react';
import Label from './Label';
import Helpblock from './Helpblock';

import FormControlFeedback from '../forms/form-control/FormControlFeedback';
import FormGroup from '../forms/form-group/FormGroup';
import { FieldSetProps } from './types';

export default function Fieldset({ id, label, tooltip, helptext, required = false, isInvalid = false, children }: FieldSetProps) {
	const helpblockId = id ? id + '_helptext' : null;

	return (
		<FormGroup className="mb-3" as="fieldset" aria-describedby={helpblockId && helptext ? helpblockId : null}>
			<Label as="legend" id={id ? id + '_label' : null} required={required} tooltip={tooltip} className={isInvalid ? 'is-invalid' : undefined}>
				{label}
			</Label>

			{children}

			<Helpblock id={helpblockId}>{helptext}</Helpblock>
			{required && <FormControlFeedback type="invalid">{label + ' is a required field'}</FormControlFeedback>}
		</FormGroup>
	);
}
