import React from 'react';
import {
	UserInterfaceContract,
	UserInterfaceContractProcessor,
	UserInterfaceResolver,
	UserInterfaceTypeResolver,
	UserInterfaceTypeResolverData
} from './types';
import { componentResolver, navResolver, simpleResolver } from './resolver';

export class UserInterfaceProcessor {
	data?: UserInterfaceContract | UserInterfaceContract[];
	typeResolver: UserInterfaceTypeResolver;

	constructor(data?: UserInterfaceContract | UserInterfaceContract[], typeResolvers?: UserInterfaceTypeResolver[]) {
		this.data = data;
		this.typeResolver = this.#extend(typeResolvers);
	}

	// loop available resolvers (in order), resolving the first match
	#extend = (resolvers: UserInterfaceTypeResolver[] = []): UserInterfaceTypeResolver => {
		return (options, processor) => {
			let result;
			const allResolvers = [...resolvers, componentResolver, navResolver];
			allResolvers.some(resolver => {
				const data = resolver(options, processor);
				if (data && data.length) {
					const [element] = data;
					if (element) {
						result = data;
						return true;
					}
				}
				return false;
			});

			if (result) {
				return result;
			}
			return processor.resolve(options);
		};
	};

	#resolveContractedTypes: UserInterfaceResolver = ({ contract, level, parent }) => {
		const [Component, props = {}] = this.typeResolver({ contract, level, parent }, { resolve: simpleResolver, process: this.#processContract });

		if (Component && contract.children && !props.children) {
			props.children = this.#processContract({
				contract: contract.children,
				level: level++,
				parent: contract
			});
		}

		return [Component, props] as UserInterfaceTypeResolverData;
	};

	#processContract: UserInterfaceContractProcessor = ({ contract: data, level, parent }) => {
		if (!data) {
			return null;
		}

		const dataArray: Array<UserInterfaceContract> = Array.isArray(data) ? data : [data];
		const result = dataArray.map((item, index) => {
			const [Component, props = {}] = this.#resolveContractedTypes({ contract: item, level, parent });
			if (!Component) {
				return null;
			}

			return <Component key={`${item.type}-${level}-${index}`} {...props} />;
		});

		return result.filter(Boolean);
	};

	render() {
		return this.#processContract({
			contract: this.data,
			level: 0
		});
	}
}
