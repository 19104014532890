import React from 'react';
import { ConditionalElement } from '../../../types';
import { IconClose } from '@optic-delight/icons';
import { FilterContext, FilterContextProps } from '../../utility/FilterContext';
import { useTranslatableText } from '../../../translatable-text';
import { Column } from '../../types';
import { Column as TanStackColumn } from '@tanstack/react-table';
import { getColumnProps } from '../../utility/tableEntitiesConverters';

interface AppliedFiltersProps<TData = unknown, TValue = unknown> {
	columns?: TanStackColumn<TData, TValue>[];
	filterLabelsMapping?: (columnId: string, value: unknown) => string | undefined;
}

export const defaultProps = {
	columns: []
};

export function AppliedFilters<TData = unknown, TValue = unknown>({
	columns = defaultProps.columns,
	filterLabelsMapping
}: AppliedFiltersProps<TData, TValue>): ConditionalElement {
	const { filterResultsLabel: label, clearAllText } = useTranslatableText();
	const filterContext = React.useContext(FilterContext) as FilterContextProps<TData, TValue>;
	const preparedColumns = columns.map(getColumnProps);

	const clearFilter = (column: Column<TData, TValue>) => {
		column.setFilter(undefined);
		filterContext.onFilterClear?.([column]);
	};

	const clearAllFilters = () => {
		preparedColumns.forEach(column => column.setFilter(undefined));
		filterContext.onFilterClear?.(preparedColumns);
	};

	return preparedColumns.length ? (
		<div className="mb-2">
			<strong>{label}</strong>
			{preparedColumns.map((column, index) => {
				return (
					<button key={column.id || index} type="button" className="btn btn-default btn-sm ms-1" onClick={() => clearFilter(column)}>
						<>
							{filterLabelsMapping?.(column.id, column.filterValue) ?? column.filterValue}
							<IconClose className="ms-1" />
						</>
					</button>
				);
			})}
			<button type="button" className="btn btn-link ms-1" onClick={clearAllFilters}>
				{clearAllText}
			</button>
		</div>
	) : null;
}
