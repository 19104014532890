import React, { MutableRefObject } from 'react';
import NavigationProvider from './NavigationProvider';
import { ActiveContent, EventKey, NavigationProps } from './types';
import { NavigationItem } from './NavigationItem';
import { NavigationDropdown } from './dropdown/NavigationDropdown';
import { NavigationDropdownItem } from './dropdown/NavigationDropdownItem';
import { useElementIds } from '../../helpers/hooks/useElementIds';

export const toggleButtonLabel = 'Toggle navigation';

export function Navigation({ type, id, defaultActiveKey, children, className }: NavigationProps): JSX.Element | null {
	if (type !== 'secondary') {
		// placeholder until primary navigation is implemented
		return null;
	}

	const [show, setShow] = React.useState(false);
	const toggleRef = React.useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>;
	const [activeContent, setActiveContent] = React.useState<ActiveContent>({ key: defaultActiveKey, content: undefined });
	const elementIds = useElementIds({ prefix: `nav-${type}`, id });
	const listClasses = ['navbar-nav', className].filter(Boolean).join(' ');

	React.useLayoutEffect(() => {
		function toggleDropdownMenuStyles() {
			if (!toggleRef.current) {
				return;
			}
			const isHidden = toggleRef.current.offsetParent === null;
			if (show && isHidden) {
				setShow(false);
			}
		}
		window.addEventListener('resize', toggleDropdownMenuStyles);
		return () => window.removeEventListener('resize', toggleDropdownMenuStyles);
	}, [show]);

	const handleSelect = (eventKey: EventKey | undefined, event: React.MouseEvent<HTMLElement>) => {
		setActiveContent({
			key: eventKey,
			content: (event.target as Element).textContent
		});
		setShow(false);
	};

	return (
		<NavigationProvider show={show} setShow={setShow} activeKey={activeContent.key} setActiveContent={setActiveContent} onSelect={handleSelect}>
			<div className="card navigation-card">
				<div className="navbar navbar-expand-lg bg-light navbar-light">
					<div className="container-fluid">
						<button
							ref={toggleRef}
							className={'navbar-toggler navbar-toggler-secondary' + (show ? '' : ' collapsed')}
							type="button"
							aria-controls={elementIds.id}
							aria-expanded={show}
							aria-label={toggleButtonLabel}
							onClick={() => setShow(!show)}>
							{activeContent.content}
						</button>
						<div id={elementIds.id} className={'collapse navbar-collapse' + (show ? ' show' : '')}>
							<ul className={listClasses}>{children}</ul>
						</div>
					</div>
				</div>
			</div>
		</NavigationProvider>
	);
}

Navigation.Item = NavigationItem;
Navigation.Dropdown = NavigationDropdown;
Navigation.DropdownItem = NavigationDropdownItem;
