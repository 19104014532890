import React from 'react';
import GlobalFilter from './global/GlobalFilter';
import ComponentArray from '../components/ComponentArray';
import { FilterResults } from './results/FilterResults';
import { rowSpacer } from './utility';
import { TableFiltersProps } from './types';
import { getColumnProps } from '../utility/tableEntitiesConverters';

export const TableFilters = <TData = unknown>({
	globalFilterEnabled,
	globalFilterProps,
	customFilters,
	actionButtons,
	useTableColumns,
	loading,
	filterLabelsMapping,
	rowCount
}: TableFiltersProps<TData>): JSX.Element => {
	const preparedColumns = useTableColumns.map(getColumnProps);

	return (
		<>
			{globalFilterEnabled || customFilters || actionButtons ? (
				<div className="row">
					{globalFilterEnabled ? (
						<div className={`col-auto ${rowSpacer}`}>
							<GlobalFilter readOnly={loading} {...globalFilterProps} />
						</div>
					) : null}
					{customFilters ? (
						<div className="col-auto me-auto">
							<ComponentArray readOnly={loading}>{customFilters?.(preparedColumns)}</ComponentArray>
						</div>
					) : null}
					{actionButtons ? (
						<div className="col-auto ms-md-auto">
							<ComponentArray readOnly={loading}>{actionButtons?.(preparedColumns)}</ComponentArray>
						</div>
					) : null}
				</div>
			) : null}
			{loading ? null : (
				<FilterResults
					filterLabelsMapping={filterLabelsMapping}
					searchCriteria={globalFilterProps?.value}
					columns={useTableColumns}
					resultsLength={rowCount}
				/>
			)}
		</>
	);
};
TableFilters.displayName = 'TableFilters';
